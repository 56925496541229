<template>
  <div>
    <h3>23 February 2010 (0.180.0)</h3>
    <p>
      <u>Binoculars</u>
      <br>- If you engage the binoculars, then you will rotate to face the binoculars.
      <br>- VFX has been added to indicate binoculars that the player has not used.
      <br>- A few Nimbus Station binoculars have been moved around and their camera paths have been updated.
      <br>- You are now prevented from using the binoculars again while you are in a binocular cinematic.
    </p>
    <p>
      <u>Looting</u>
      <br>- Loot now supports Round Robin and Free for All modes.
      <br>- Loot now displays which player is able to loot a certain object.
      <br>- Loot is textured to indicate whose loot is whose.
    </p>
    <p>
      <u>Graphical User Interface</u>
      <br>- New scrollbar functionality
      <br>- The chat button will change depending on the filtering results of entered text.
      <br>- Added images for Item Rollover Tips:
      <br>- Heart
      <br>- Imagination
      <br>- Armor
      <br>- Combo Stats
      <br>- *NEW* vendor items are able to be streamed in, instead of all coming at once and lagging out the client.
      <br>- Vendors will now buy items from you.
      <br>- You will now be able to see why you can’t get Mission Rewards if your Backpack is full.
      <br>- You will now be able to see why you can’t buy certain items from vendors.
    </p>
    <p>
      <u>Character Creation</u>
      <br>- Updated eyes to have correct scale and position on head.
      <br>- Updated eyebrows to correct size and position on face.
      <br>- Added 3 new brows.
      <br>- Fixed an alpha issue with the torso decal icons.
      <br>- There is currently a white background behind all of these icons. This will be gone!
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Added temporary visual destination signs for Nimbus Station Plaza.
      <br>- Shortened (&amp; tweaked) landing cinematics upon arrival at NS from each other world.
      <br>- Adjusted the landing position at Forbidden Valley &amp; Gnarled Forest launchers.
      <br>- In the Concert Scene, instrument quickbuilds will reset if you exit while playing the instrument.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Added temporary rock in Avant Gardens tunnel to smooth out edge that Minifigures were getting hung up on.
      <br>- Added the Maelstrom VFX to Wisp’s hands.
      <br>- Fan button quickbuild now takes 2 seconds instead of 3 to complete.
      <br>- The pet button on top of the Sentinel Wall has been removed.
      <br>- Rusty Steele has a new wrench!
      <br>- Mardolf has finalized animations!
    </p>
    <p>
      <u>Venture Explorer</u>
      <br>- SFX will look and work better.
      <br>- Players will now be awarded an achievement for getting Imagination when Bob’s first mission is complete.
    </p>
    <p>
      <u>Chat</u>
      <br>- Chatting whitelist has been updated. You can now say many of the game terms and other phrases.
    </p>
    <p>
      <u>Sound</u>
      <br>- Footsteps on different surfaces will sound different now.
    </p>
    <p>
      <u>Backpack</u>
      <br>- The Backpack and LEGOBag have been combined into one widget.
    </p>
    <p>
      <u>Tutorials</u>
      <br>- The Camera Orbit tutorial is now bigger and clearer.
      <br>- Emote tutorials have been fixed.
      <br>- Players can no longer walk about during the rocket landing cinematic.
      <br>- Completed quickbuilds will remain built through their entire countdown even if the player who completed it logs off first.
    </p>
    <p>
      <u>Build</u>
      <br>- Players can now interact with models along their edges, rather than their centers.
      <br>- A character's animation speed during a quickbuild is based on the number of bricks thrown over the duration of the build.
    </p>
  </div>
</template>
